<template>
    <div class="titlediv">
        <a id="map"></a>
        <h1 v-if="currentInfo.name">
            What ties does <span id="university_name">{{ currentInfo.name }} </span> have to Israel?
        </h1>
        <h1 v-else>What ties do Belgian universities have to Israel?</h1>

        <div v-if="showWarning" class="warning">
            <h2 class="warning">Warning. Some ties among the ones listed here may be unchecked.</h2>
        </div>
    </div>
    <div class="containerMain">
        <div class="map">
            <img class="mapImage" :src="require('../assets/BE.svg')" />

            <img id="IMEC" class="punt IMEC" @click="selectUni('IMEC')" :src="require('../assets/' + dots[IMEC])"
                title="Interuniversity Microelectronics Centre" />

            <img id="VIB" class="punt VIB" @click="selectUni('VIB')" :src="require('../assets/' + dots[VIB])"
                title="Vlaams Instituut voor Biotechnologie" />

            <img id="ULG" class="punt ULG" @click="selectUni('ULG')" :src="require('../assets/' + dots[ULG])"
                title="Université de Liège" />

            <img id="VUB" class="punt VUB" @click="selectUni('VUB')" :src="require('../assets/' + dots[VUB])"
                title="Vrije Universiteit Brussel" />

            <img id="UCL" class="punt UCL" @click="selectUni('UCL')" :src="require('../assets/' + dots[UCL])"
                title="Université catholique de Louvain" />

            <img id="KUL" class="punt KUL" @click="selectUni('KUL')" :src="require('../assets/' + dots[KUL])"
                title="Katholieke Universiteit Leuven" />

            <img id="tUL" class="punt tUL" @click="selectUni('tUL')" :src="require('../assets/' + dots[tUL])"
                title="Transnationale Universiteit Limburg" />

            <img id="UN" class="punt UN" @click="selectUni('UN')" :src="require('../assets/' + dots[UN])"
                title="Université de Namur" />

            <img id="UA" class="punt UA" @click="selectUni('UA')" :src="require('../assets/' + dots[UA])"
                title="Universiteit Antwerpen" />

            <img id="ULB" class="punt ULB" @click="selectUni('ULB')" :src="require('../assets/' + dots[ULB])"
                title="Université Libre de Bruxelles" />

            <img id="UM" class="punt UM" @click="selectUni('UM')" :src="require('../assets/' + dots[UM])"
                title="Université de Mons" />

            <img id="UG" class="punt UG" @click="selectUni('UG')" :src="require('../assets/' + dots[UG])"
                title="Universiteit Gent" />

            <img id="UH" class="punt UH" @click="selectUni('UH')" :src="require('../assets/' + dots[UH])"
                title="Universiteit Hasselt" />
        </div>
        <div class="map">
            <div class="blurb">
                <ShortSummary :blurb="currentInfo" />

                <div v-if="currentInfo.name" class="moveToMiddle">
                    <a href="#map"><button class="blurbBeginNoColour" type="button">Back to map</button></a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import info from "../assets/info.json";
import ShortSummary from "./ShortSummary.vue";

export default {
    name: "InteractiveMap",
    components: {
        ShortSummary,
    },
    props: {
        msg: String,
    },
    emits: ["select-uni"],
    data() {
        return {
            dots: ["smalldot.svg", "bigdot.svg", "smalldot_2.svg", "bigdot_2.svg"],
            ULG: 0,
            VUB: 0,
            UCL: 0,
            KUL: 0,
            tUL: 0,
            UN: 0,
            UA: 0,
            ULB: 0,
            UM: 0,
            UG: 0,
            UH: 0,
            IMEC: 2,
            VIB: 2,
            prevSelected: "UCL",
            currentInfo: {
                shortForm: "nuthin",
                uni: "",
            },
            info: info,
            showWarning: process.env.VUE_APP_SHOW_WARNING == 1,
        };
    },

    methods: {

        selectUni(uni) {

            //add a check if uni not in list
            if (!(uni in this.info)) { return }
            if ((this.prevSelected == 'VIB') | (this.prevSelected == 'IMEC')) {
                eval("this." + this.prevSelected + "= 2");
            }
            else {
                eval("this." + this.prevSelected + "= 0");
            }
            if ((uni == 'VIB') | (uni == 'IMEC')) {
                eval("this." + uni + "= 3");
                console.log("in if")
            }
            else {
                eval("this." + uni + "= 1");
            }
            if (uni != this.prevSelected) {
                document.getElementById(uni).style.zIndex = "1";
                document.getElementById(this.prevSelected).style.zIndex = "2";
            }
            this.prevSelected = uni;
            this.getSlideFromID(uni);
            localStorage.setItem("shortUni", uni);
            localStorage.setItem("nameUni", "LongName");
        },
        getSlideFromID(newID) {
            //TODO: change chapter if new chapter
            this.currentInfo = this.info[newID];
            //TODO: add error handler if next slide does not exist. (e.g. go to start or page not found)
            if (!this.currentInfo) {
                console.log("could not find uni");
                console.log(newID);
            }
        },
        clickToSelectUni() {
            this.$emit("select-uni", this.currentInfo);
            window.location.assign("./Database.html");
        },
    },
    created() {
        this.$watch(
            () => this.$route.params.uni,
            (newId) => {
                this.selectUni(newId)
            }
        )
    },
};

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.mapImage {
    max-height: calc(100vh - 168px);
    max-width: min(calc(100vw - 30px), 600px);
    /* margin-left: 10px;
  margin-right: 20px; */
    padding: 0pt;
    z-index: 0;
    position: relative;
    left: 0;
}

.map {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    width: fit-content;
    padding: 0pt;
    flex: content;

}

.blurb {
    margin: 20px 10px;
    padding: 0;
    align-content: center;
    text-align: center;
}

.punt {
    width: 5%;
    z-index: 2;
    position: absolute;
    cursor: pointer;
}

.ULG {
    left: 74%;
    top: 42%;
}

.VUB {
    left: 47%;
    top: 33%;
}

.UCL {
    left: 52%;
    top: 40%;
}

.KUL {
    left: 54.5%;
    top: 32%;
}

.FUSL {
    left: 49%;
    top: 35%;
}

.tUL {
    left: 76.7%;
    top: 32.3%;
}

.UN {
    left: 57%;
    top: 49.3%;
}

.UA {
    left: 45.5%;
    top: 17%;
}

.ULB {
    left: 45.5%;
    top: 34.5%;
}

.UM {
    left: 35.2%;
    top: 50%;
}

.UG {
    left: 30.5%;
    top: 24%;
}

.UH {
    left: 68%;
    top: 29.4%;
}

.IMEC {
    left: 53.5%;
    top: 33%;
}

.VIB {
    left: 30.7%;
    top: 25.5%;
}
</style>
