<template>
  <div class="house">
    <div class="midcontainer">
      <!--<MenuBar />-->
      <InteractiveMap msg="" @select-uni="selectUni" />
      <div class="titlediv">
      </div>
      <div class="blurbEnd">
        <h2>Why map the ties between Belgian Universities and Israel?</h2>
        <p>
          In late 2023 and early 2024, in response to the ongoing <a
            href="https://www.ohchr.org/sites/default/files/documents/hrbodies/hrcouncil/sessions-regular/session55/advance-versions/a-hrc-55-73-auv.pdf"
            target="_blank" style="color: #2c2e31">genocide</a> in Palestine perpetrated by the state of Israel,
          students and staff of Belgian
          universities increased pressure on their institutions to cut ties with Israeli companies and universities
          complicit in this crime against humanity.
          Students and staff have been calling for the academic boycott of Israel for many years prior, because Israeli
          academic institutions have been complicit in the commission of <a
            href="https://www.amnesty.org/en/documents/mde15/5141/2022/en/" style="color: #2c2e31"
            target="_blank">apartheid</a>, <a href="https://www.alhaq.org/advocacy/20931.html" style="color: #2c2e31"
            target="_blank">colonization</a>, and <a href="https://news.un.org/en/story/2022/10/1129942"
            style="color: #2c2e31" target="_blank">occupation</a> against the Palestinian
          people for many decades.
          This website serves as an information source in support of their activities, and for anyone else interested in
          this issue.
        </p>
        <p>
          Based on a variety of sources, including the CORDIS-website with EU funded research projects, university
          websites, results from Freedom of Information requests and the work of Dr. Maya Wind, this website maps
          institutional ties that are current as of May 2024. It builds on research by Stop Wapenhandel, the Rights
          Forum, Students for Palestine and students and staff at various Belgian universities, and is a work in progress.
        </p>
        <h3>What does this overview show?</h3>
        <p>
          Apart from ties with Israeli entities - companies, universities, state bodies and research institutions - the
          overview includes long-term relations with major international military security companies that provide arms
          and technologies to the Israeli Defence Forces and/or are involved in ongoing cooperation with the Israeli
          arms industry.
        </p>
        <p>
          In line with the calls of the <a href="https://bdsmovement.net/academic-boycott#guidelines" target="_blank"
            style="color: #2c2e31">international BDS movement</a>, the overview focuses on institutional ties and does
          not include collaborations between individual academics. We mapped all ties between Belgian universities and
          Israeli academic institutions, since all Israeli academic institutions are documented and known to be actively
          complicit in the violation of Palestinian rights. This makes them subject to the <a style="color: #2c2e31"
            href="https://bdsmovement.net/academic-boycott" target="_blank">PACBI/BDS call for academic boycott</a>.
         </p>
         <p> 
            We also included all ties of Belgian universities with Israeli companies, because many of them have been
          documented and known to be involved in military and security-related work and therefore subject to <a
            href="https://bdsmovement.net/economic-boycott" style="color: #2c2e31" target="_blank">the economic
            boycott</a>. Some companies listed are not obviously complicit in the violation of Palestinian rights, but
          all of them are part of an economy based in Palestinian dispossession. <b>We encourage further research into
            all ties and partners</b>.

          <!--In line with the <a style="color: #2c2e31" href="https://bdsmovement.net/academic-boycott"
            target="_blank">calls of the international BDS movement</a>, the overview focuses on institutional ties and
          does not include collaborations between individual academics. <b>Note that not all listed Israeli entities are
            involved in military or security-related work, or have the same level of complicity in the violation of
            Palestinian rights</b>. We document all ties with Israeli companies because the BDS movement calls for the
          <a href="https://bdsmovement.net/economic-boycott#tab2" style="color: #2c2e31" target="_blank">economic
            boycott of all Israeli products</a>. We map all ties with Israeli academic institutions because all of them
          are known to be actively complicit in the violation of Palestinian rights, and so long as they are are subject
          to the <a href="https://bdsmovement.net/academic-boycott#guidelines" target="_blank"
            style="color: #2c2e31">PACBI/BDS call for boycott</a>.-->
        </p>
        <p>
          One important type of relationship is missing from this overview: universities' investments,
          e.g. shares in companies. Such investments are made by separate university funds, which do
          not publish their portfolios.
        </p>
        <div class="moveToMiddle">
          <button class="blurbbegin"
            onclick="window.open('https://stopwapenhandel.org/european-universities-cooperation-with-israel-in-eu-security-research-programs/')"
            type="button">See the work of Stop Wapenhandel on Israeli-academic ties</button>
          <button class="blurbbegin"
            onclick="window.open('https://www.democracynow.org/2024/3/15/maya_wind_towers_of_ivory_and')"
            type="button">Listen to Dr. Maya Wind: how Israeli universities are complicit in aggression against
            Palestinians</button>
        </div>
      </div>
      <!--
        <p>Funding from fossil companies has been shown to bias research in favour of fossil fuels, and may
          influence the direction of research towards technologies which will least disrupt fossil companies' business
          model, rather than those most necessary for an energy transition.
        </p>
        <p>The presence of such companies on campus - at careers fairs, guest lectures and sponsoring students
          organisations,
          for example - can be seen as a tacit endorsement by the university of them and their activities, and
          contributes to their 'social licence to operate', i.e. their perceived legitimacy in the eyes of the public.
        </p>
        <p>Increasingly, academics and students are demanding that their research institutions stop association with
          fossil companies. This would target a key blocker of the energy transition, namely the influence of fossil
          fuel companies on the direction of research & development, public opinion and policymakers.</p>
        -->
      <!--
      <div class="blurbBeginNoColour">
        <h2>About the coalition</h2>
        <p>This project involves collaboration by a <b>coalition of local and national
            organisations</b>, together forming the 'Mapping Fossil Ties Coalition'. We support and coordinate
          decentralised research into relationships between academia and the fossil fuel industry. We do this through
          decentralised information gathering on campuses, freedom of information (FOI) requests, and crowdsourcing.</p>
        <div id="logos" class="moveToMiddle">
          <a href="https://endfossil.com/" target="_blank"><img class="thumb"
              :src="require('./assets/logo_thumbs/logo_EFO.png')" width="80"></a>
          <a href="https://gofossilfree.org/nl/" target="_blank"><img class="thumb"
              :src="require('./assets/logo_thumbs/logo_fv.png')" width="80"></a>
          <a href="https://milieudefensie.nl/over-ons/milieudefensie-jong" target="_blank"><img class="thumb"
              :src="require('./assets/logo_thumbs/logo_MDJ.png')" width="80"></a>
          <a href="https://verbiedfossielereclame.nl/" target="_blank"><img class="thumb"
              :src="require('./assets/logo_thumbs/logo_rf.jpg')" width="80"></a>
          <a href="https://scientists4future.nl/" target="_blank"><img class="thumb"
              :src="require('./assets/logo_thumbs/logo_s4f.png')" width="80"></a>
          <a href="https://www.scientistrebellion.nl/" target="_blank"><img class="thumb"
              :src="require('./assets/logo_thumbs/logo_sr.png')" width="80"></a>
          <a href="https://www.solid-sustainability.org/" target="_blank"><img class="thumb"
              :src="require('./assets/logo_thumbs/logo_ssr2.jpg')" width="80"></a>
          <a href="https://socialtippingpointcoalitie.nl/" target="_blank"><img class="thumb"
              :src="require('./assets/logo_thumbs/logo_STPC.png')" width="80"></a>
          <a href="http://universityrebellion.nl/" target="_blank"><img class="thumb"
              :src="require('./assets/logo_thumbs/logo_UR.png')" width="80"></a>
        </div>

        <div class="moveToMiddle"><button class="blurbbegin" onclick="window.location.assign('./About.html')"
            type="button">Read more</button>
        </div>
      </div>
      
      <div class="blurbEndNoColour">
          <h2 class="moveToMiddle">How can I help?</h2>

        <div class="containInfoBoxes">
          <div class="infoBox">
            <p>Want to research fossil ties at your place of work or study?</p>
            <button class="blurbbegin" onclick="window.open('https://www.solid-sustainability.org/handbook')"
            type="button">Check out our handbook!</button>
          </div>
          <div class="infoBox">
            <p>
            Do you know of a fossil tie that's not on this website? 
            </p>
              <button class="blurbbegin" onclick="window.open('https://framaforms.org/submit-information-about-a-relationship-between-a-university-and-fossil-fuel-company-1678282225')"
            type="button">Submit information!</button>
          </div>
          <div class="infoBox"><p>Do you want to investigate fossil ties in another country?</p>
            <button class="blurbbegin" onclick="window.open('mailto:info@stopwapenhandel.org')"
            type="button">Get in touch!</button>
            </div>
        </div>
      </div>
    -->
    </div>
  </div>
  <footer>
    <p><b>Questions, comments or ideas for this database? <a class="linkDarkTheme"
          href="mailto:academiccomplicity@proton.me">Contact us!</a></b></p>
    <p><b>Content</b>: <a  class="linkDarkTheme" href="https://stopwapenhandel.org/" target="_blank">Stop Wapenhandel</a> and <a class="linkDarkTheme" href="https://elsc.support/" target="_blank">European Legal Support Center</a>, supported by a team of volunteers. <b>Website
        design</b>: <a  class="linkDarkTheme" href="https://solid-sustainability.org" target="_blank">Solid Sustainability Research</a> © 2024</p>
  </footer>
</template>

<script>
import InteractiveMap from './components/InteractiveMap.vue'
//import MenuBar from './components/MenuBar.vue'
import './assets/css/main.css';

export default {
  name: 'App',
  components: {
    //  MenuBar,
    InteractiveMap
  },
  data() {
    return {
      uniNonSelected: true,
      shortUni: String,
      nameUni: String,
      width: document.documentElement.clientWidth
    }
  },
  methods: {
    selectUni(uni) {
      this.shortUni = uni.shortForm
      this.nameUni = uni.uni
      this.uniNonSelected = false
    },
    deselectUni() {
      this.uniNonSelected = true
    },
    goToFullDatabase() {
      localStorage.removeItem("shortUni")
      localStorage.removeItem("nameUni")
      window.location.assign('./Database.html')
    },
    changeClass() {
      this.width = document.documentElement.clientWidth;
    }
  },
  mounted() {
    localStorage.removeItem("shortUni")
    localStorage.removeItem("nameUni")
    // window.addEventListener('resize', this.changeClass);
  }
}
</script>


<style></style>