<template>

    <div v-if="blurb.name" class="parentContainer">

        <div v-if="blurb.ties" :class="classVariable">
          <div  v-if="Object.entries(blurb.ties).length == 0" >
            <h2 class="warning">
                We haven't found any collaborations at this university.
            </h2><h2 class="warning">
                Do you know of one? <a href="mailto:academiccomplicity@proton.me">Get in touch!</a>
            </h2>
          </div>

          <div v-else>
            <div class="uniCount">
              <h2>
                The <span id="university_name">{{blurb.name}}</span> <br>
                <span v-if="blurb.name == 'Vlaams Instituut voor Biotechnologie'">is an institution with staff at UGent, KULeuven,<br>UAntwerpen, VUB, IMEC and UHasselt, and <br></span>
                <span v-if="blurb.name == 'Interuniversity Microelectronics Centre'">is an institution with research groups affiliated with <br> UGent, KULeuven, UAntwerpen, VUB, and UHasselt, and <br></span>
                collaborates on <span class="countValue"> {{Object.values(blurb.ties).reduce((a,c) => a + c.length, 0 )}} </span> projects  <br>
                with <span class="countValue">{{Object.entries(blurb.ties).length}} </span> Israeli partners or companies arming Israel.
              </h2>
            </div>
          </div>

            <div v-for="(instituteTies, key) in blurb.ties" :key="key" class="tieItem">
                <h3>{{ key }}</h3>
                <div v-if="partners.hasOwnProperty(key)">
                    <p class="partnerDescription">
                        <span v-html="partners[key].length < 250
                                ? partners[key]
                                : partners[key].substring(0, 250) + '... '
                            "></span><span v-if="partners[key].length > 249"
                            style="text-decoration: underline; cursor: pointer" @click="() => TogglePopup(key)">Read
                            More</span>
                    </p>
                </div>
                <PopUp v-if="this.partnerTriggerValue[key]" :TogglePopup="() => TogglePopup(key)">
                    <h2>{{ key }}</h2>
                    <p v-html="partners[key]"></p>
                </PopUp>

                <div v-for="tie in instituteTies" :key="tie.description" class="tieText">
                    <p v-html="tie.description"></p>
                    <ul v-if="tie.URLs">
                        <li v-for="URL in tie.URLs" :key="URL">
                            <a :href="URL" target="_blank">Source</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <div :class="classVariable">
            <div v-if="blurb.groups.length > 0">
                <h3>Groups campaigning for Palestine:</h3>
                <div v-for="item in blurb.groups" :key="item.name" class="tieItem">
                    <img :src="require('../assets/logo_thumbs/' + item.img)" class="icon" />
                    <div class="tieText">
                        <a :href="item.link" target="_blank">{{ item.name }}</a>
                    </div>
                </div>
            </div>
        </div>

        <div class="moveToMiddle disclaimer">
            We try to keep this overview up to date and accurate. If you have
            any new information or corrections, please
            <a href="mailto:academiccomplicity@proton.me">contact us</a>.
        </div>
    </div>
    <h3 v-else>Select an institution to find out more</h3>
</template>

<script>
import partners from "../assets/partners.json";
import PopUp from "./PopUp.vue";
//import { ref } from 'vue';

export default {
    name: "ShortSummary",
    components: {
        PopUp,
    },
    props: {
        blurb: {
            type: Object,
            default() {
                return {
                    shortForm: "nuin",
                    uni: "",
                };
            },
        },
    },
    data() {
        return {
            width: document.documentElement.clientWidth,
            partners: partners,
            partnerTriggerValue: [],
        };
    },
    mounted() {
        window.addEventListener("resize", this.changeClass);
        for (var key in partners) {
            this.partnerTriggerValue[key] = false;
        }
    },
    computed: {
        classVariable() {
            if (this.width < 610) {
                return "smallBlurb";
            } else {
                return "largeBlurb";
            }
        },
        legend() {
            if (this.width < 610) {
                return "legendSmall";
            } else {
                return "legendLarge";
            }
        },
    },
    methods: {
        changeClass() {
            this.width = document.documentElement.clientWidth;
        },
        TogglePopup(key) {
            this.partnerTriggerValue[key] = !this.partnerTriggerValue[key];
        },
    },
    setup() {
        /*	const popupTriggers = ref({
        for (var key in partners) {
          key: false,
        }
      });

      const TogglePopup = (key) => {
        popupTriggers.value[key] = !popupTriggers.value[key]
      }

      return {
        PopUp,
        popupTriggers,
        TogglePopup
      } */
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.blurb {
    margin: 20px;
    padding: 0;
    align-content: center;
    text-align: center;
}

.smallBlurb {
    width: 100%;
    flex-grow: 1;
}

.largeBlurb {
    width: 60%;
    flex-grow: 1;
}

.icon {
    width: 56px;
    height: 56px;
    margin-left: 7px;
    margin-right: 7px;
    padding-top: 2px;
    padding-bottom: 2px;
    margin-top: auto;
    margin-bottom: auto;
}

.iconMiddle {
    width: 60px;
    height: 60px;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
}

.iconLegend {
    width: 40px;
    height: 40px;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: auto;
    margin-bottom: auto;
}

.iconsTogether {
    width: 140px;
}

.subsection {
    text-align: left;
    display: flex;
}

h3 {
    margin: 8px 0px;
    margin-top: 12px;
    margin-bottom: 6px;
    padding: 0px;
    text-align: left;
}

p {
    margin-top: 0px;
    margin-bottom: 5px;
}

.subsectionLegend {
    text-align: left;
    /*display: flex;*/
    border: grey 1px solid;
    padding: 10px;
}

.legendItem {
    display: flex;
}

.subsectiondescription {
    padding-left: 10px;
    align-items: center;
}

.parentContainer {
    display: flex;
    flex-flow: row wrap;
}

.legendLarge {
    width: 36%;

    padding: 2%;
    float: left;
    left: 20vw;
    /*padding: 10px
  /*flex-grow: 4*/
}

.legendSmall {
    padding: 2%;
    /*padding: 10px
  /*flex-grow: 4*/
    margin-left: auto;
    margin-right: auto;
}

</style>
