import { createApp } from 'vue'
import App from '../App.vue'
import { createWebHistory, createRouter } from 'vue-router'
import InteractiveMap from "@/components/InteractiveMap.vue";

const router = createRouter({
    history: createWebHistory(),
    routes: [{ path: '/:uni', component: InteractiveMap }],
})

createApp(App).use(router).mount('#app')